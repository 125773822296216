import { AvsStatusEnum } from '~/entities/AVS'

export class Details {
    public country!: {active: boolean, code: string, id:string, name:string};
    public company!: string;
    public firstName!: string;
    public lastName!: string;
    public streetAddress!: string; // get rid of this, this is address 1
    public address1!: string;
    public address2!: string;
    public address3!: string;
    public addressId!:number;
    public neighborhood?: string;
    public city!: string;
    public state!: {country:string | null, id:string, name?:string, stateprov:string ,stateprovcode:string, label?:string};
    public postalCode!: string;
    public phone!: string;
    public streetNumber?: string;
    public county!: string;
    public isResidential!: boolean;
    public validationSource!: any;
    public validationLevel!: any;
    public isChanged!: boolean;
    public errorMessage!: string;
    public errorCode!: string | number;
    public passedAvs: boolean = false;
    public avsStatus!: AvsStatusEnum; 
    public success!: boolean;
    public requote?: boolean;
    public isSavedAddress!: boolean;
    public isEventAddress!: boolean;
    public needSaveAddress?: boolean;
    public displayName?: string;
    public avsChecked?: boolean;
    public searchType?: string;
    public avsProblem?: boolean; // if avs failed, can notificate the user a problem with the address
  
    // validations
    // validations
    public phoneValid!: boolean
  
    public constructor(init?:Partial<Details>) {
      if(init){
        Object.assign(this, init);
        if (!this.avsStatus) {
          this.avsStatus = new AvsStatusEnum();
        }
        if (!this.needSaveAddress) {
          this.needSaveAddress = false;
        }
      }else{
        this.company = '';
        this.firstName = '';
        this.lastName = '';
        this.streetAddress = '';
        this.streetNumber = '';
        this.county = '';
        this.address1 = '';
        this.address2 = '';
        this.address3 = '';
        this.city = '';
        this.postalCode = '';
        this.phone = '';
        this.avsStatus = new AvsStatusEnum();
        this.phoneValid = false;
        this.isSavedAddress = false;
        this.needSaveAddress = false;
      }
    }


    public isFilled(){
      return (this.country &&
        this.firstName && this.firstName.trim() &&
        this.lastName && this.lastName.trim() &&
        this.streetAddress && this.streetAddress.trim() &&
        this.city && this.city.trim() &&
        ((this.country as any).code !== 'US' || this.state) &&
        this.postalCode && this.postalCode.trim() &&
        this.phone);
    }

    public isAddressFilled(){
      return (this.country &&
        this.city && this.city.trim() &&
        ((this.country as any).code !== 'US' || this.state) &&
        this.postalCode && this.postalCode.trim());
    }

    private mapAddressComponents(address_components){
      let components = {} as any;
      if(address_components.constructor == Array && address_components.length){
        for(let i = 0; i < address_components.length; i++){
          let component = address_components[i];
          let type = component.types[0];
          switch (type) {
            case 'street_number':
              components.streetNumber = component.long_name;
              break;
            case 'route':
              components.streetAddress = (components.streetNumber && !component.long_name.includes(components.streetNumber) ? components.streetNumber : '') + ' ' + component.long_name;
              components.streetAddress = components.streetAddress.trim();
              if(!components.address1)
                components.address1 = component.short_name;
              break;
            case 'subpremise':
              components.address2 = component.long_name && !isNaN(component.long_name as number) ? '#'+(component.long_name as string) : (component.long_name as string);
              break;
            case 'postal_code':
              components.postalCode = component.long_name;
              break;
            case 'neighborhood':
              components.neighborhood = component.long_name;
              break;
            case 'locality':
              components.city = component.long_name;
              break;
            case 'country':
              components.countryCode = component.short_name;
              components.country = component.long_name;
              break;
            case 'administrative_area_level_2':
              components.county = component.long_name;
              break;
            case 'administrative_area_level_1':
              components.state = component.short_name;
              components.stateName = component.long_name;
              break;
            default:
              console.log(`unknown address component: `+type);
          }
        }
        if(components.neighborhood){
          components.city = components.neighborhood;
        }
      }
      return components;
    }

    public mapComponentsToAddress() {
      const components = this;
      const addressComponent:any = [] ;
    
      if (components.streetNumber) {
        addressComponent.push({
          long_name: components.streetNumber,
          short_name: components.streetNumber,
          types: ['street_number']
        });
      }
    
      if (components.streetAddress) {
        addressComponent.push({
          long_name: components.streetAddress,
          short_name: components.address1 || components.streetAddress,
          types: ['route']
        });
      }
    
      if (components.address2) {
        addressComponent.push({
          long_name: components.address2.replace('#', ''),
          short_name: components.address2.replace('#', ''),
          types: ['subpremise']
        });
      }
    
      if (components.postalCode) {
        addressComponent.push({
          long_name: components.postalCode,
          short_name: components.postalCode,
          types: ['postal_code']
        });
      }
    
      if (components.neighborhood) {
        addressComponent.push({
          long_name: components.neighborhood,
          short_name: components.neighborhood,
          types: ['neighborhood']
        });
      }
    
      if (components.city) {
        addressComponent.push({
          long_name: components.city,
          short_name: components.city,
          types: ['locality']
        });
      }
    
      if (components.country) {
        addressComponent.push({
          long_name: components.country.name,
          short_name: components.country.code,
          types: ['country']
        });
      }
    
      if (components.county) {
        addressComponent.push({
          long_name: components.county,
          short_name: components.county,
          types: ['administrative_area_level_2']
        });
      }

      if (components.state) {
        addressComponent.push({
          long_name: typeof components.state === 'string' ? components.state : components.state.stateprov,
          short_name: typeof components.state === 'string' ? components.state : components.state.stateprovcode,
          types: ['administrative_area_level_1']
        });
      }
      return addressComponent;
    }

    public setStage(trip:any, countries, states?){
      const components = this.mapAddressComponents(trip);
      for(let step = 0; step < countries.length; step++){
        if(countries[step].code === components.countryCode){
          this.country = countries[step];
        }
      }
      if (!this.country) {
        this.country = components.countryCode;
      }
      this.streetAddress = components.streetAddress?components.streetAddress:this.streetAddress;
      this.address1 = components.address1?components.address1:this.address1;
      this.address2 = components.address2?components.address2:this.address2;
      this.address3 = components.address3?components.address3:this.address3;
      this.streetNumber = components.streetNumber?components.streetNumber:this.streetNumber;
      this.county = components.county?components.county:this.county;
      this.city = components.city?components.city:this.city;
      this.postalCode = components.postalCode?components.postalCode:this.postalCode;
      if(this.country && this.country.code && states?.[this.country.code]){
        for(let step = 0; step < states[this.country.code].length; step++){
          if(components.state === states[this.country.code][step].stateprovcode){
            this.state = states[this.country.code][step];
          }
        }
      } else {
        this.state = components.state
      }
    }

    public fillDetails(details): void {
      if(details){
        if(details.company)
          this.company = details.company;
        if(details.firstName)
          this.firstName = details.firstName;
        if(details.lastName)
          this.lastName = details.lastName;
        if(details.streetAddress && (typeof details.streetAddress === 'string' || details.streetAddress instanceof String) && details.streetAddress.trim())
          this.streetAddress = details.streetAddress.trim();
        if(details.address1 && (typeof details.address1 === 'string' || details.address1 instanceof String) && details.address1.trim())
          this.address1 = details.address1.trim();
        if(details.address2)
          this.address2 = details.address2;
        if(details.address3)
          this.address3 = details.address3;
        if(details.city)
          this.city = details.city;
        if(details.phone){
          this.phone = details.phone;
          this.phoneValid = true;
        }
        if(details.isResidential !== undefined){
          this.isResidential = details.isResidential;
        }
        if(details.passedAvs !== undefined){
          this.passedAvs = details.passedAvs;
        }
        if(details.addressId !== undefined){
          this.addressId = details.addressId;
        }
        if(details.isSavedAddress !== undefined){
          this.isSavedAddress = details.isSavedAddress;
        }
        if (details.avsStatus !== undefined) {
          this.avsStatus = details.avsStatus;
        }
        if(details.isEventAddress !== undefined){
          this.isEventAddress = details.isEventAddress;
        }
        if(details.displayName !== undefined){
          this.displayName = details.displayName;
        }
      }
    }
}

export class StageDetails {
  public from!: Details;
  public to!: Details;

  constructor(init?:Partial<StageDetails>){
      if(init){
          Object.assign(this, init);
      }else{
          this.from = new Details();
          this.to = new Details();
      }
  }
}